import { useEffect } from "react";
import { createPortal } from "react-dom";
import { useLocation } from "react-router-dom";
import { ErrorBoundary } from "@/react/components/shared/ErrorPages/ErrorBoundary";
import { usePunditUserContext } from "@circle-react/contexts";
import { useSpacesContext } from "@circle-react/contexts";
import { useSupportWidgetContext } from "../SupportWidgetContext";
import { SupportWidgetError } from "../SupportWidgetError";
import { SupportWidgetPortalBody } from "../SupportWidgetPortalBody";
import { WIDGET_HIDDEN_ROUTES } from "../constants";
import { getLocalStorage, setLocalStorage } from "../utils";

export const SupportWidgetPortal = ({ showWidgetBot }) => {
  const location = useLocation();
  const { records: spaces } = useSpacesContext();
  const { currentCommunityMember } = usePunditUserContext();

  // Remove empty keys from local storage that depend on conversation id
  const localStorageObject = getLocalStorage();
  for (const key in localStorageObject) {
    if (
      (key.match(/conversation-view-\d+-comment/) &&
        localStorageObject[key] === "") ||
      (key.match(/conversation-view-\d+-attachments/) &&
        localStorageObject[key].length === 0) ||
      key === "isChatWindowExpanded"
    ) {
      delete localStorageObject[key];
    }
  }
  setLocalStorage(localStorageObject);

  const shouldHideSupportWidget = !currentCommunityMember?.is_admin;

  const chatSpaces = spaces.filter(space => space.post_type === "chat");
  const chatSpacesSlugs = chatSpaces.map(space => space.slug);
  const routesWithWidgetHidden = WIDGET_HIDDEN_ROUTES.concat(chatSpacesSlugs);

  const isWidgetHiddenOnRoute = routesWithWidgetHidden.some(route =>
    location.pathname.includes(route),
  );

  const { openWidget } = useSupportWidgetContext();

  useEffect(() => {
    if (showWidgetBot) {
      setLocalStorage({
        ...getLocalStorage(),
        location: "/ask",
      });
      openWidget();
    }
  }, []);

  if (shouldHideSupportWidget || isWidgetHiddenOnRoute) {
    return null;
  }

  return (
    <>
      {createPortal(
        <ErrorBoundary renderFunc={SupportWidgetError}>
          <SupportWidgetPortalBody />
        </ErrorBoundary>,
        document.body,
      )}
    </>
  );
};
