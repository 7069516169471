import { ErrorBoundary } from "@/react/components/shared/ErrorPages/ErrorBoundary";
import { GDPRCookieBanner } from "@circle-react/components/GDPR/GDPRCookieBanner";
import { MasqueradeBanner } from "@circle-react/components/MasqueradeBanner";
import { RailsFlashMessages } from "@circle-react/components/RailsFlashMessages";
import { SupportWidgetPortal } from "@circle-react/components/SupportWidget/SupportWidgetPortal";
import { twoFaPath } from "@circle-react/helpers/urlHelpers";
import { applyV3Fonts } from "@circle-react/providers/Theme";
import { Routes } from "@circle-react/routes/Main";
import { CommunitySwitcherAppData } from "@circle-react/wrappers/CommunitySwitcherAppData";
import { HeaderV3 } from "@circle-react-shared/uikit/HeaderV3";
import { AdminOnlyRouteNotice } from "@circle-react-shared/uikit/HeaderV3/NavigationBar/AdminOnlyRouteNotice";

export interface MainAppContentProps {
  notice: string;
  alert: string;
  shouldDisplayHeader: boolean;
  shouldDisplayCommunitySwitcher: boolean;
  hasMinimalLayout: boolean;
  showWidgetBot: boolean;
}

export const MainAppContent = ({
  notice,
  alert,
  shouldDisplayHeader,
  shouldDisplayCommunitySwitcher,
  hasMinimalLayout,
  showWidgetBot,
}: MainAppContentProps) => {
  const isNotTwoFaPage = window.location.pathname !== twoFaPath();
  applyV3Fonts();

  return (
    <div className="flex">
      {shouldDisplayCommunitySwitcher && (
        <ErrorBoundary>
          <CommunitySwitcherAppData />
        </ErrorBoundary>
      )}
      <div className="w-full">
        <RailsFlashMessages notice={notice} alert={alert} />
        <MasqueradeBanner />
        {shouldDisplayHeader && !hasMinimalLayout && isNotTwoFaPage && (
          <HeaderV3 />
        )}
        <Routes />
        <SupportWidgetPortal showWidgetBot={showWidgetBot} />
        <GDPRCookieBanner />
        <AdminOnlyRouteNotice />
      </div>
    </div>
  );
};
