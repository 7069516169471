import { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useParams } from "react-router-dom";
import { t } from "@/i18n-js/instance";
import { DIRECT_CHAT_ROOM } from "@/react/components/constants";
import { ErrorBoundary } from "@/react/components/shared/ErrorPages/ErrorBoundary";
import { useWebSocket } from "@/react/hooks/useWebSocket";
import { usePunditUserContext } from "@circle-react/contexts";
import { isIntendedOnNewTab } from "@circle-react/helpers/browserHelpers";
import { dateStringToTimeAgo } from "@circle-react/helpers/dateTimeHelpers/timeAgo";
import { chatRoomPath } from "@circle-react/helpers/urlHelpers";
import { useChatsSidebarApi } from "@circle-react/hooks/chatsV2/useChatsSidebarApi";
import { Icon } from "@circle-react-shared/Icon";
import { UserImageStatus } from "@circle-react-shared/UserImageStatus";
import { Loader } from "@circle-react-uikit/Loader";
import { usePopoverPortalContext } from "@circle-react-uikit/PopoverPortal";
import { GroupUserImage } from "../GroupUserImage";
import { NoMessagesContainer } from "../NoMessagesContainer";

const badgeStyles =
  "rounded font-semibold leading-none text-white text-xxs bg-v2-danger p-[3px] min-w-[20px] text-center mt-1 mr-0.5";

const DMsListComponent = () => {
  const { uuid: chatRoomUuid } = useParams();
  const { onClose } = usePopoverPortalContext();
  const { currentCommunityMember, isLoading: isCommunityLoading } =
    usePunditUserContext();
  const {
    chatRooms,
    hasNextPage,
    isLoading: isChatRoomsLoading,
    onEventReceive,
    fetchNextPage,
  } = useChatsSidebarApi({
    openChatRoomUuid:
      chatRoomUuid && chatRoomUuid !== "new" ? chatRoomUuid : null,
  });

  useWebSocket(
    {
      channel: "ChatRoomChannel",
      onMessageReceive: data => {
        onEventReceive(data, currentCommunityMember?.id);
      },
      community_member_id: currentCommunityMember?.id,
      canCreateConnection: true,
    },
    [chatRooms],
  );

  const isLoading = isCommunityLoading || isChatRoomsLoading;

  const chatRoomWithMessages = useMemo(
    () => chatRooms.filter(chatRoom => chatRoom.last_message),
    [chatRooms],
  );

  if (isLoading) {
    return <Loader center />;
  }

  if (!chatRoomWithMessages.length) {
    return (
      <div className="h-[532px] 2xl:h-[592px]">
        <NoMessagesContainer />
      </div>
    );
  }

  const dots = ": ";

  return (
    <div
      id="scrollableDmsDiv"
      className="h-[532px] overflow-auto 2xl:h-[592px]"
    >
      <InfiniteScroll
        style={{ height: "100%", overflow: "hidden" }}
        scrollThreshold={0.8}
        next={fetchNextPage}
        hasMore={hasNextPage}
        dataLength={chatRoomWithMessages.length}
        scrollableTarget="scrollableDmsDiv"
        loader={<Loader center />}
      >
        {chatRoomWithMessages.map(chatRoom => {
          const {
            chat_room_name,
            chat_room_kind,
            last_message,
            current_participant = {},
            other_participants_preview: otherParticipants = [],
            id,
            unread_messages_count,
            uuid,
          } = chatRoom;

          const isDirect = chat_room_kind === DIRECT_CHAT_ROOM;
          return (
            <Link
              className="chat-room-item hover:bg-tertiary block cursor-pointer px-5 py-2.5"
              key={id}
              onClick={e => {
                if (!isIntendedOnNewTab(e)) {
                  onClose();
                }
              }}
              to={chatRoomPath({ uuid })}
              data-testid="chat-room-dm-list"
            >
              <div className="flex items-start justify-between">
                <div className="flex">
                  {isDirect ? (
                    <div className="isolate mr-4 mt-1">
                      <UserImageStatus
                        src={otherParticipants[0]?.avatar_url}
                        name={otherParticipants[0]?.name}
                        alt={t("alt_avatar")}
                        status={otherParticipants[0]?.status}
                        size="8"
                      />
                    </div>
                  ) : (
                    <GroupUserImage
                      user1={otherParticipants[0]}
                      user2={otherParticipants[1]}
                    />
                  )}
                  <div>
                    <div className="flex items-center space-x-2">
                      <p className="text-darkest truncate text-sm font-medium">
                        {chat_room_name}
                      </p>
                    </div>
                    <div className="mt-0.5">
                      <p className="message-body chat-message-word-wrap">
                        {last_message.sender.community_member_id ===
                        currentCommunityMember?.id
                          ? t("messaging.you")
                          : last_message.sender.name}
                        {dots}
                        {last_message.body ||
                          last_message.rich_text_body?.circle_ios_fallback_text}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="flex h-full flex-col items-end justify-start">
                  <p className="text-default flex min-w-[86px] items-end justify-end text-xs">
                    {dateStringToTimeAgo(last_message.sent_at)}
                  </p>
                  <div className="mr-1 flex">
                    {current_participant.muted ? (
                      <Icon
                        type="16-bell-snooze"
                        size={16}
                        className="text-timestamp"
                      />
                    ) : (
                      unread_messages_count > 0 && (
                        <span
                          className={badgeStyles}
                          data-testid="unread-messages-count"
                        >
                          {unread_messages_count}
                        </span>
                      )
                    )}
                  </div>
                </div>
              </div>
            </Link>
          );
        })}
      </InfiniteScroll>
    </div>
  );
};

const ChatError = () => (
  <div className="chat-room__error">{t("chat_space.error_message")}</div>
);

export const DMsList = () => (
  <ErrorBoundary renderFunc={ChatError}>
    <DMsListComponent />
  </ErrorBoundary>
);
