import { ChatError } from "@/react/components/Spaces/ChatSpace/Messenger/ChatError";
import { ErrorBoundary } from "@/react/components/shared/ErrorPages/ErrorBoundary";
import type {
  ChatSortType,
  ChatType,
} from "@circle-react/types/CommunityBot/ChatSort";
import { ChatListComponent } from "./ChatList";

export const ChatList = ({
  activeSort,
  activeChatType,
  activeAgent,
}: {
  activeSort: ChatSortType;
  activeChatType: ChatType;
  activeAgent: { name: string; id: number };
}) => (
  <ErrorBoundary renderFunc={ChatError}>
    <ChatListComponent
      activeSort={activeSort}
      activeChatType={activeChatType}
      activeAgent={activeAgent}
    />
  </ErrorBoundary>
);
