import type { ReactNode } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { SomethingWentWrong } from "@/react/components/shared/ErrorPages/SomethingWentWrong";
import { withErrorBoundary } from "@/react/components/shared/ErrorPages/WithErrorBoundary";
import { LayoutContextProvider } from "@circle-react/components/SettingsApp/Layout/LayoutContext";
import { SpacesContextProvider } from "@circle-react/contexts";
import type { BackendProps } from "@circle-react/providers/AppContext";
import { AppContextProvider } from "@circle-react/providers/AppContext";
import { reactQueryConfig } from "@circle-react/providers/QueryClient/reactQueryConfig";
import { ToastProvider } from "@circle-react-uikit/ToastV2";

const queryClient = new QueryClient(reactQueryConfig);
const renderSomethingWentWrong = () => <SomethingWentWrong />;

interface SettingsAppV1ProviderProps extends BackendProps {
  children: ReactNode;
}

export const SettingsAppV1Provider = withErrorBoundary(
  ({
    notice,
    alert,
    display_community_switcher,
    children,
    ...rest
  }: SettingsAppV1ProviderProps) => (
    <QueryClientProvider client={queryClient}>
      <AppContextProvider
        backendProps={{
          notice,
          alert,
          display_community_switcher,
          ...rest,
        }}
      >
        <SpacesContextProvider>
          <ToastProvider>
            <LayoutContextProvider>{children}</LayoutContextProvider>
          </ToastProvider>
        </SpacesContextProvider>
      </AppContextProvider>
    </QueryClientProvider>
  ),
  renderSomethingWentWrong,
);
